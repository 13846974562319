import parse, { Element } from 'html-react-parser';
import sanitizeHtml from 'sanitize-html';
import { useConfig } from '../context/config';
import { DynamicText, DynamicTextEnum } from 'src/constants/richText';

//https://github.com/remarkablemark/html-react-parser?tab=readme-ov-file#replace-with-typescript
const RichContent = ({ content }) => {
  const config = useConfig()!;
  const sanitizedContent = sanitizeHtml(content, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2', 'img']),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      img: ['src', 'alt'],
      '*': ['data-dynamic', 'class', 'src'], // Allow the 'data-dynamic' attribute on all tags
    },
    transformTags: {
      '*': (tagName, attribs) => {
        if (
          attribs['data-dynamic'] &&
          !Object.values(DynamicTextEnum).includes(attribs['data-dynamic'])
        ) {
          delete attribs['data-dynamic']; // Remove the attribute if its value is not allowed
        }
        return { tagName, attribs }; // Return the modified tag and attributes
      },
    },
  });

  return (
    <div>
      {parse(sanitizedContent, {
        replace(domNode) {
          if (domNode instanceof Element && domNode.attribs['data-dynamic']) {
            return <DynamicText config={config} dynamicText={domNode.attribs['data-dynamic']} />;
          }
          return domNode;
        },
      })}
    </div>
  );
};

export default RichContent;
