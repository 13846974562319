import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { useFormSubmit } from './Form';
import { FormSkip } from './FormSkip';
import Button from './Button';
import { FullBlockRoute } from 'src/types/blocks';
import { useFormStepProps } from '../context/FormStepPropsProvider';
import { useFormContext } from 'react-hook-form';
import { CodeFormId } from '../steps/Code';

export function FormPrimaryAction() {
  const props = useFormStepProps();
  const { formState } = useFormContext();
  const {
    isSubmitting: isFormSubmitting,
    activeForm,
    activeStepIndex,
    formSteps,
    formValues,
    handleFormGroupSkip,
  } = useFormSubmit();
  const isLoginPage = useMatch(FullBlockRoute.Login);
  const isFinalStep = formSteps?.length - 1 === activeStepIndex;

  const isSubmitDisabledByValidation = useMemo(() => {
    return props.disableInvalidSubmit ? !formState.isValid : false;
  }, [formState.isValid, props.disableInvalidSubmit]);

  const text = useMemo(() => {
    if (props.btnText) return props.btnText;
    if (isFinalStep) return 'Submit';
    return 'Continue';
  }, [isFinalStep, props]);

  const hidePrimaryButton = useMemo(() => {
    if (props.isPrimaryButtonHidden) {
      return true;
    }
    if (isLoginPage) {
      return activeForm === CodeFormId && formValues.email.length && !formValues.codeSent;
    }
    return false;
  }, [isLoginPage, activeForm, formValues, props.isPrimaryButtonHidden]);

  if (hidePrimaryButton) {
    return null;
  }

  if (props.isPrimaryButtonSkip) {
    return (
      <FormSkip
        text={text}
        isFormSubmitting={isFormSubmitting}
        handleFormGroupSkip={handleFormGroupSkip}
      />
    );
  }
  return (
    <Button
      isSubmitting={isFormSubmitting}
      isDisabled={isSubmitDisabledByValidation}
      baseClass="btn mod-primary mod-tall"
      type="submit"
      text={text}
      handleSubmit={() => {}}
    />
  );
}
