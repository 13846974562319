import {
  faQuestionCircle,
  faSteeringWheel,
  faTruck,
  faWrench,
} from '@fortawesome/pro-regular-svg-icons';
import { AppointmentType, AppointmentTypeDropdown } from 'src/types/appointment';

export const AppointmentTypeInputs = [
  {
    label: 'Sales Consultation',
    icon: faSteeringWheel,
    value: AppointmentType.Sales,
  },
  {
    label: 'Vehicle Service',
    icon: faWrench,
    value: AppointmentType.Service,
  },
  {
    label: 'Vehicle Delivery',
    icon: faTruck,
    value: AppointmentType.Delivery,
  },
  {
    label: 'Something Else',
    icon: faQuestionCircle,
    value: AppointmentType.Other,
  },
];

export const AppointmentTypeDropdownArray: AppointmentTypeDropdown[] = [
  { label: 'Consultation', value: AppointmentType.Sales, isDefault: true },
  { label: 'Service', value: AppointmentType.Service, isDefault: false },
  { label: 'Delivery', value: AppointmentType.Delivery, isDefault: false },
  { label: 'Other', value: AppointmentType.Other, isDefault: false },
];
