import { faFileContract, faMoneyBillWave, faUniversity } from '@fortawesome/pro-regular-svg-icons';
import { PaymentType } from 'src/types/deal';

export const PaymentMethodInputs = [
  {
    label: 'Financing',
    labelDesc: 'Monthly payments go towards ownership',
    value: PaymentType.Financing,
    isDisabledKey: 'disableFinancing',
    icon: faUniversity,
  },
  {
    label: 'Leasing',
    labelDesc: 'Rent a vehicle for a set period',
    value: PaymentType.Leasing,
    isDisabledKey: 'disableLeasing',
    icon: faFileContract,
  },
  {
    label: 'Pay Once',
    labelDesc: 'Pay for the vehicle upfront',
    value: PaymentType.Cash,
    isDisabledKey: 'disableCash',
    icon: faMoneyBillWave,
  },
];
