import { TradeinCondition } from 'src/types/tradein-block';
import { Radio } from './Radio';
import { FormError } from './FormError';

const TradeinConditionInputs = [
  {
    label: 'Excellent (uncommon)',
    labelDesc: 'Looks new and in perfect mechanical condition',
    value: TradeinCondition.Excellent,
  },
  {
    label: 'Good (most common)',
    labelDesc: 'Some repairable cosmetic defects and is free of major mechanical issues',
    value: TradeinCondition.Good,
  },
  {
    label: 'Fair (common)',
    labelDesc: 'Has mechanical or cosmetic issues that require repair and/or replacement',
    value: TradeinCondition.Fair,
  },
];

export const Condition = () => {
  return (
    <>
      {TradeinConditionInputs.map(item => (
        <Radio
          key={item.value}
          value={item.value}
          name="condition"
          label={item.label}
          labelDesc={item.labelDesc}
          circle={true}
        />
      ))}
      <FormError name="condition" />
    </>
  );
};
