import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { useDocuments } from 'src/fetches/useDocuments';

export const useOtherDocumentStatus = () => {
  const { documents } = useDocuments();

  // filter down to documents that have tag of other
  const otherDocuments = documents?.filter(item => item.tag === 'other');

  return useMemo(() => {
    if (otherDocuments?.length) {
      return BlockStatus.Done;
    }

    return BlockStatus.Default;
  }, [otherDocuments]);
};
