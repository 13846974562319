import { Employer, JobTitle } from '../inputs/Input';
import { FormBody, FormContentWrapper, useFormSubmit } from '../form/Form';
import { employer, jobTitle } from 'src/constants/schemas';
import { useCustomFormStep } from '../form/FormStepProps';

export const EmployerStepId = 'employerForm';
export const Employment = ({ index }) => {
  const { handleFormGroupSubmit, formValues: payment } = useFormSubmit();
  const employment = payment.employment[index];
  const stepProps = useCustomFormStep(EmployerStepId);

  const onSubmit = async (updatedFormData: any) => {
    const saveData = payment.employment;
    saveData[index] = { ...saveData[index], ...updatedFormData };

    handleFormGroupSubmit({ employment: saveData });
  };

  const baseProps = {
    id: EmployerStepId,
    title: 'What is the name of your employer?',
    body: 'Please provide your employment information.',
    schema: { employer, jobTitle },
  };
  const formStepProps = {
    ...baseProps,
    ...stepProps,
    id: EmployerStepId + index,
    data: {
      employer: employment.employer,
      jobTitle: employment.jobTitle,
      monthlyIncome: employment.monthlyIncome,
      employmentYears: employment.employmentYears,
      employmentMonths: employment.employmentMonths,
    },
    onSubmit,
  };

  return (
    <FormContentWrapper {...formStepProps}>
      <FormBody />
      <Employer />
      <JobTitle />
    </FormContentWrapper>
  );
};
