import Button from './Button';

export function FormSkip({ text, isFormSubmitting, handleFormGroupSkip }) {
  return (
    <Button
      type="button"
      text={text}
      isSubmitting={isFormSubmitting}
      baseClass="btn mod-primary mod-tall"
      handleSubmit={handleFormGroupSkip}
    />
  );
}
