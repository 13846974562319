import { HomeOwnership } from 'src/types/creditapplication-block';

export const HomeOwnershipInputs = [
  {
    label: 'Renting',
    name: 'homeOwnership',
    labelDesc: 'I rent the property from a landlord',
    value: HomeOwnership.Rent,
  },
  {
    label: 'Own with Mortgage',
    name: 'homeOwnership',
    labelDesc: 'I own and make mortgage payments',
    value: HomeOwnership.Mortgage,
  },
  {
    label: 'Own',
    name: 'homeOwnership',
    labelDesc: 'I own the property and it is paid off',
    value: HomeOwnership.Own,
  },
  {
    label: 'Other',
    name: 'homeOwnership',
    labelDesc: 'Any other arrangement',
    value: HomeOwnership.Other,
  },
];
