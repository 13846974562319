import { useMemo } from 'react';
import RichContent from './RichContent';
import { AdditionalFieldControl } from 'src/components/controls/AdditionalField';
import './FormAdditionalContent.css';
import { StepInternal } from 'src/types/step';

export function FormAdditionalContent(props?: Partial<StepInternal>) {
  const renderClass = useMemo(() => {
    if (!props) return '';
    const classes = ['form--additional-content'];
    if (props.alignment) classes.push(`is-${props.alignment}`);
    return classes.join(' ');
  }, [props]);

  if (!props) return null;

  return (
    // Everthing in additional Content
    <div className={renderClass}>
      {props.richText && <RichContent content={props.richText} />}
      {props.additionalFields?.map(field => (
        <AdditionalFieldControl key={field.name} fieldConfig={field} />
      ))}
    </div>
  );
}
