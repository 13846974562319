import {
  faCalendarStar as faCalendarStarRegular,
  faCars,
  faCheckDouble,
  faFile,
  faMoneyBillWave,
  faBars,
  faFileCheck,
  faThumbsUp,
  faFolderPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { BlockRoute, DocumentItem, FullBlockRoute, RouteItem } from 'src/types/blocks';
import { WebsiteRoute } from '../external/WebsiteRoutes';
import { faCalendarStar } from '@fortawesome/pro-solid-svg-icons';
import { DocumentTag } from 'src/types/document-block';

// Lower Nav Items (Trade In, Finance, Apply, Appointments, Menu)
export const TradeInRoute: RouteItem = {
  disabled: false,
  name: BlockRoute.TradeIn,
  title: 'Trade In',
  navTitle: 'Trade',
  moduleTitle: 'Add a Trade-in',
  route: FullBlockRoute.TradeIn,
  websiteRoute: WebsiteRoute.TradeIn,
  icon: faCars,
  isDealDependent: false,
};

export const FinanceRoute: RouteItem = {
  disabled: false,
  name: BlockRoute.Finance,
  title: 'Finance',
  navTitle: 'Finance',
  moduleTitle: 'Apply For Financing',
  subtitle: 'Explore financing options',
  route: FullBlockRoute.Finance,
  icon: faMoneyBillWave,
  iconDone: faMoneyBillWave,
  isDealDependent: true,
  tip: 'To explore financing, add a vehicle.',
};

export const ApplyRoute: RouteItem = {
  disabled: false,
  name: BlockRoute.Apply,
  title: 'Apply',
  navTitle: 'Apply',
  moduleTitle: 'Apply For Financing',
  route: FullBlockRoute.Apply,
  websiteRoute: WebsiteRoute.CreditApplication,
  icon: faFolderPlus,
  isDealDependent: false,
};

export const AppointmentsRoute: RouteItem = {
  disabled: false,
  name: BlockRoute.Appointments,
  title: 'Book an Appointment',
  navTitle: 'Visit',
  moduleTitle: 'Visit the Showroom',
  subtitle: 'Schedule a pick up or delivery',
  subtitleDone: 'Next: ',
  route: FullBlockRoute.Appointments,
  websiteRoute: WebsiteRoute.Appointments,
  icon: faCalendarStarRegular,
  iconDone: faCalendarStar,
  isDealDependent: true,
};

export const MenuRoute: RouteItem = {
  disabled: false,
  name: BlockRoute.Menu,
  title: 'Menu',
  route: FullBlockRoute.Menu,
  icon: faBars,
  isDealDependent: false,
};

export const LowerNavMenuItems: RouteItem[] = [
  TradeInRoute,
  FinanceRoute,
  ApplyRoute,
  AppointmentsRoute,
  MenuRoute,
];

// Menu Items
export const VehiclesRoute: RouteItem = {
  disabled: false,
  name: BlockRoute.Vehicle,
  title: 'My Cars',
  moduleTitle: 'Find the Right Car',
  navTitle: 'My Cars',
  route: FullBlockRoute.Vehicle,
  websiteRoute: WebsiteRoute.Inventory,
  icon: faCars,
  isDealDependent: false,
};

export const PreferencesRoute: RouteItem = {
  disabled: false,
  name: BlockRoute.Preferences,
  title: 'My Account',
  route: FullBlockRoute.Preferences,
  icon: faCheckDouble,
  isDealDependent: false,
};

export const DocumentsRoute: RouteItem = {
  disabled: false,
  name: BlockRoute.Documents,
  title: 'Documents',
  navTitle: 'Docutments',
  subtitle: 'Pre-Upload Paperwork',
  alternateTitle: 'My Docs',
  websiteRoute: WebsiteRoute.Documents,
  route: FullBlockRoute.Documents,
  icon: faFile,
  isDealDependent: false,
  isIndexPage: true,
};

export const LoginRoute: RouteItem = {
  disabled: false,
  name: BlockRoute.Login,
  title: 'Verification',
  navTitle: 'Verification',
  route: FullBlockRoute.Login,
  icon: faCheckDouble,
  isDealDependent: false,
};

export const DriversLicenseFrontRoute: DocumentItem = {
  name: DocumentTag.DriversLicenseFront,
  title: 'Drivers License',
  titleSpecific: 'Drivers License (front)',
  icon: faFile,
  iconDone: faFileCheck,
  route: `${FullBlockRoute.Documents}${DocumentTag.DriversLicenseFront}`,
  parentBlock: BlockRoute.Documents,
};

export const DriversLicenseBackRoute: DocumentItem = {
  name: DocumentTag.DriversLicenseBack,
  title: 'Drivers License',
  titleSpecific: 'Drivers License (back)',
  route: `${FullBlockRoute.Documents}${DocumentTag.DriversLicenseBack}`,
  parentBlock: BlockRoute.Documents,
  icon: faFile,
  iconDone: faFileCheck,
};

export const InsuranceRoute: DocumentItem = {
  name: DocumentTag.Insurance,
  title: 'Insurance',
  route: `${FullBlockRoute.Documents}${DocumentTag.Insurance}`,
  parentBlock: BlockRoute.Documents,
  icon: faFile,
  iconDone: faFileCheck,
};

export const IncomeProofRoute: DocumentItem = {
  name: DocumentTag.Income,
  title: 'Income',
  route: `${FullBlockRoute.Documents}${DocumentTag.Income}`,
  parentBlock: BlockRoute.Documents,
  icon: faFile,
  iconDone: faFileCheck,
};

export const ResidenceProofRoute: DocumentItem = {
  name: DocumentTag.Residence,
  title: 'Residence',
  route: `${FullBlockRoute.Documents}${DocumentTag.Residence}`,
  parentBlock: BlockRoute.Documents,
  icon: faFile,
  iconDone: faFileCheck,
};

export const OtherDocumentsRoute: DocumentItem = {
  name: DocumentTag.Other,
  title: 'Other Documents',
  route: `${FullBlockRoute.Documents}${DocumentTag.Other}`,
  parentBlock: BlockRoute.Documents,
  icon: faFile,
  iconDone: faFileCheck,
};

export const ReferencesRoute: RouteItem = {
  disabled: false,
  name: BlockRoute.References,
  title: 'References',
  subtitle: 'Enter financial references',
  alternateTitle: 'My references',
  websiteRoute: WebsiteRoute.Documents,
  route: FullBlockRoute.References,
  icon: faFile,
  iconDone: faThumbsUp,
  isDealDependent: false,
};

export const MenuPageItems: RouteItem[] = [
  VehiclesRoute,
  PreferencesRoute,
  DocumentsRoute,
  AppointmentsRoute,
];

export const blocks = {
  // Customizable
  [BlockRoute.TradeIn]: TradeInRoute,
  [BlockRoute.Finance]: FinanceRoute,
  [BlockRoute.Apply]: ApplyRoute,
  [BlockRoute.Appointments]: AppointmentsRoute,
  // Fixed
  [BlockRoute.Menu]: MenuRoute,
  [BlockRoute.Vehicle]: VehiclesRoute,
  [BlockRoute.Preferences]: PreferencesRoute,
  [BlockRoute.Documents]: DocumentsRoute,
  [BlockRoute.Login]: LoginRoute,
};
