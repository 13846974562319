import { useEffect } from 'react';
import { EmailForm, EmailFormId } from '../steps/Email';
import { CodeForm, CodeFormId } from '../steps/Code';
import toast from 'react-hot-toast';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { FormWrapper } from '../form/Form';
import { useAuth } from '../context/AuthProvider';
import { FormPropsProvider } from '../context/FormPropsProvider';
import { useSessionContext } from '../context/SessionProvider';

export enum LoginState {
  Verify = 'verify',
}

export function LoginForm() {
  const { token } = useAuth();
  const { sessionCustomer } = useSessionContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const initialEmail = sessionCustomer?.email || '';

  let baseProps = {
    form: 'login',
    data: {
      email: initialEmail,
      code: '',
      codeSent: false,
    },
    requireEmail: true,
    conversion: {
      analyticsEventName: 'Login',
    },
  };

  const handleFormSubmit = async () => {
    try {
      const returnUrl = location.state?.from?.pathname || '/';
      const addParams = location.state?.from?.search;
      navigate(addParams ? `${returnUrl}${addParams}` : returnUrl, { replace: true });
    } catch (e: any) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    const message = searchParams.get('message');

    if (message === 'loggedOut') {
      toast.success('You were successfully logged out');
    }
    if (message === 'needsAuth') {
      toast.success('Please log in again');
    }
    if (message) {
      searchParams.delete('message');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (token) {
      navigate('/');
    }
  }, [token, navigate]);

  if (token) {
    return null;
  }

  return (
    <FormPropsProvider props={baseProps}>
      <FormWrapper
        handleFormSubmit={handleFormSubmit}
        handleFormCancel={() => {
          navigate(-1);
        }}
        multistepFormValues={baseProps.data}
        steps={
          initialEmail !== '' && location.state === LoginState.Verify
            ? [CodeFormId]
            : [EmailFormId, CodeFormId]
        }
      >
        <EmailForm />
        <CodeForm />
      </FormWrapper>
    </FormPropsProvider>
  );
}
