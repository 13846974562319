import { createDynamicSchema } from 'src/constants/schemas';
import { useConfig } from '../context/config';
import { VisibleAdditionalField } from 'src/types/additionalField';
import { Field } from 'src/types/field';
import { useFormProps } from '../context/FormPropsProvider';
import { useFormStepProps } from '../context/FormStepPropsProvider';
import { FormOptionsInternal } from 'src/types/form';
import { StepInternal } from 'src/types/step';

export function useCustomFields() {
  const formProps = useFormProps<FormOptionsInternal>();
  const props = useFormStepProps<StepInternal>();
  const config = useConfig()!;
  const customStep = formProps.steps?.find(item => item.id === props.id);

  let schema = {};

  const additionalFields = customStep?.additionalFields?.filter(
    field => field.type !== 'hidden'
  ) as VisibleAdditionalField[];

  if (additionalFields?.length) {
    for (const field of additionalFields) {
      schema = {
        ...schema,
        [field.name]: createDynamicSchema(field.name as Field, config, field.validation),
      };
    }
  }

  const extraDefaultValues = additionalFields?.reduce((acc, field) => {
    acc[field.name] = field.defaultValue;
    return acc;
  }, {});

  return {
    title: customStep?.title,
    body: customStep?.body,
    alignment: customStep?.alignment,
    additionalFields: customStep?.additionalFields,
    schema,
    extraDefaultValues,
    richText: customStep?.richText,
  };
}
