export enum Field {
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  applicantType = 'applicantType',
  applicationMode = 'applicationMode',
  birthDate = 'birthDate',
  blockId = 'blockId',
  calendarAppointmentDate = 'calendarAppointmentDate', //internal
  city = 'city',
  color = 'color',
  comments = 'comments',
  condition = 'condition',
  creditScore = 'creditScore',
  currentCell = 'currentCell',
  dealerId = 'dealerId',
  driversLicenseNumber = 'driversLicenseNumber',
  driversLicenseState = 'driversLicenseState',
  email = 'email',
  employmentStatus = 'employmentStatus',
  employmentMonths = 'employmentMonths',
  employmentYears = 'employmentYears',
  employer = 'employer',
  employerPhone = 'employerPhone',
  esignature = 'esignature',
  esignatureConsent = 'esignatureConsent',
  firstName = 'firstName',
  homeLivingMonths = 'homeLivingMonths',
  homeLivingYears = 'homeLivingYears',
  homeMonthlyPayment = 'homeMonthlyPayment',
  homeOwnership = 'homeOwnership',
  inputAppointmentDate = 'inputAppointmentDate',
  inputCode = 'inputCode',
  jobTitle = 'jobTitle',
  lastName = 'lastName',
  location = 'location',
  make = 'make',
  middleName = 'middleName',
  mileage = 'mileage',
  model = 'model',
  monthlyIncome = 'monthlyIncome',
  monthlyPaymentTarget = 'monthlyPaymentTarget',
  notes = 'notes',
  optoutCalls = 'optoutCalls',
  optoutEmails = 'optoutEmails',
  optoutSms = 'optoutSms',
  otherMonthlyIncome = 'otherMonthlyIncome',
  otherMonthlyIncomeSource = 'otherMonthlyIncomeSource',
  paymentLender = 'paymentLender',
  paymentMethod = 'paymentMethod',
  paymentType = 'paymentType',
  payoffType = 'payoffType',
  payoffAmount = 'payoffAmount',
  payoffLender = 'payoffLender',
  phone = 'phone',
  photos = 'photos',
  previousAddressLine1 = 'previousAddressLine1',
  previousAddressLine2 = 'previousAddressLine2',
  previousCity = 'previousCity',
  previousEmployer = 'previousEmployer',
  previousEmploymentMonths = 'previousEmploymentMonths',
  previousEmploymentStatus = 'previousEmploymentStatus',
  previousEmploymentYears = 'previousEmploymentYears',
  previousHomeLivingMonths = 'previousHomeLivingMonths',
  previousHomeLivingYears = 'previousHomeLivingYears',
  previousHomeMonthlyPayment = 'previousHomeMonthlyPayment',
  previousHomeOwnership = 'previousHomeOwnership',
  previousJobTitle = 'previousJobTitle',
  previousMonthlyIncome = 'previousMonthlyIncome',
  previousZip = 'previousZip',
  relationship = 'relationship',
  ssn = 'ssn',
  softPullConsent = 'softPullConsent',
  searchBy = 'searchBy',
  status = 'status',
  state = 'state',
  time = 'time',
  tradeinDeclaration = 'tradeinDeclaration',
  type = 'type',
  ucgvehicleid = 'ucgvehicleid',
  vin = 'vin',
  year = 'year',
  zip = 'zip',
}

export type SelectOption = { label: string; value: string; isDefault?: boolean };
export type SelectOptions = SelectOption[];

export interface FieldInput {
  name: string; // Ex: 'dealerId'
  label: string; // In use
  type: string;
  placeholder?: string;
  required?: boolean;
  defaultValue?: string;
  options?: SelectOptions;
}
