import { useMemo } from 'react';
import { BlockRoute, BlockStatus } from 'src/types/blocks';
import { useSessionContext } from '../SessionProvider';
import { useConfig } from '../config';
import { useUploadSteps } from 'src/helpers/useUploadSteps';

export const useDocumentStatus = (
  referencesStatus: BlockStatus,
  creditApplicationStatus: BlockStatus
) => {
  const { blockOrder: uploadSteps } = useUploadSteps();
  const config = useConfig()!;
  const { documentsVisited } = useSessionContext();

  return useMemo(() => {
    if (config.blocks[BlockRoute.Documents].disabled) {
      return BlockStatus.Hidden;
    }

    if (!uploadSteps.length) return BlockStatus.Default;

    if (
      uploadSteps.every(step => step.status === (BlockStatus.Done || BlockStatus.Hidden)) &&
      creditApplicationStatus !== BlockStatus.Incomplete &&
      creditApplicationStatus !== BlockStatus.Default &&
      referencesStatus !== BlockStatus.Incomplete &&
      referencesStatus !== BlockStatus.Default
    ) {
      return BlockStatus.Done;
    }

    // User has been to documents page
    if (documentsVisited === true) {
      return BlockStatus.Incomplete;
    }

    return BlockStatus.Default;
  }, [uploadSteps, documentsVisited, config, referencesStatus, creditApplicationStatus]);
};
