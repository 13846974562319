import { useConfig } from '../context/config';
import { useFormProps } from '../context/FormPropsProvider';

export const useCustomFormStep = (id: string) => {
  const config = useConfig()!;
  const props = useFormProps();

  const customGlobalStep = config.forms?.global?.steps?.find(item => item.id === id);
  const customFormStep = config.forms?.[props.form]?.steps?.find(item => item.id === id);

  return customGlobalStep || customFormStep || undefined;
};
