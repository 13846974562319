/* eslint-disable react-hooks/exhaustive-deps */
import toast from 'react-hot-toast';
import { FormBody, FormContentWrapper, useFormSubmit } from '../form/Form';
import { AuthService } from 'src/services/Auth';
import { useAuth } from '../context/AuthProvider';
import { useState } from 'react';
import { useCustomFormStep } from '../form/FormStepProps';
import { Authenticator } from '../controls/Authenticator';

export const CodeFormId = 'loginCodeForm';
export const CodeForm = () => {
  const stepProps = useCustomFormStep(CodeFormId);
  const {
    handleFormGroupSubmit,
    formValues,
    isSubmitting: isFormSubmitting,
    setIsSubmitting,
  } = useFormSubmit();
  const { signin } = useAuth();
  const [displayEmail, setDisplayEmail] = useState(formValues.email);
  const [codeSent, setCodeSent] = useState(formValues.codeSent);

  const onSubmit = async (data: any) => {
    if (isFormSubmitting) return;

    setIsSubmitting(true);
    try {
      await AuthService.verifyCode(data.code);
      const user = await AuthService.getToken();

      signin(user, () => {
        handleFormGroupSubmit(data);
      });
    } catch (e: any) {
      setIsSubmitting(false);
      toast.error(e.message);
    }
  };

  const baseProps = {
    id: 'loginCodeForm',
    title: 'We need to verify your identity',
    body: codeSent
      ? `To continue check your ${displayEmail} inbox for an email containing your temporary
          sign in code. Copy and paste it here to submit.`
      : 'Click “Send Me A Code” and we will send a code to your email. Enter it here to get a head start on buying a car.',
    onSubmit,
  };

  const formStepProps = {
    ...baseProps,
    ...stepProps,
  };

  return (
    <FormContentWrapper {...formStepProps}>
      <FormBody />
      <Authenticator
        setDisplayEmail={setDisplayEmail}
        codeSent={codeSent}
        setCodeSent={setCodeSent}
      />
    </FormContentWrapper>
  );
};
