import { useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faCircle } from '@fortawesome/pro-regular-svg-icons';

import './Select.css';
import { Colors } from 'src/types/colors';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

export type SelectOption = { value: string; label: string; disabled?: boolean };

interface SelectProps {
  name: string;
  label?: string;
  className?: string;
  placeholder?: string;
  hidePlaceholder?: boolean;
  disabled?: boolean;
  selectOptions?: SelectOption[];
  icon?: any;
  status?: boolean;
}

export const Select = ({
  name,
  label,
  disabled,
  selectOptions,
  className,
  placeholder,
  icon,
  hidePlaceholder = false,
  status = false,
}: SelectProps) => {
  const { control } = useFormContext();
  const {
    field: { value: fieldValue, ...field },
    fieldState: { invalid, isTouched, isDirty },
    formState: { errors, isSubmitted },
  } = useController({
    name,
    control,
  });

  const renderClass = useMemo(() => {
    const classes = className ? [className, 'select--container'] : ['select--container'];
    if (disabled) classes.push('is-disabled');
    if ((isSubmitted || isTouched) && isDirty) classes.push('is-entered');
    if ((isSubmitted || isTouched) && invalid) classes.push('is-error-field');
    return classes.join(' ');
  }, [className, invalid, isDirty, isSubmitted, isTouched, disabled]);

  const selectClass = useMemo(() => {
    const classes = ['select'];

    if (fieldValue) {
      classes.push('filled');
    }
    return classes.join(' ');
  }, [fieldValue]);

  // if (selectOptions?.length === 0 || !selectOptions) {
  //   console.warn(
  //     'Oops! We do not have any select options to render. There is probably a config issue'
  //   );
  //   return null;
  // }

  return (
    <div className={renderClass}>
      <div className="form--input--holder">
        {label ? (
          <label className="form--label" htmlFor={name}>
            <span className="form--label--bg"></span>
            <span className="form--label--text">{label}</span>
          </label>
        ) : null}
        <div className="select--holder">
          {status && (
            <FontAwesomeIcon
              icon={fieldValue ? faCheckCircle : faCircle}
              className="select--icon"
              color={!disabled ? (fieldValue ? Colors.Green1 : Colors.Gray0) : Colors.Gray5}
            />
          )}
          {icon && <FontAwesomeIcon icon={icon} className="select--icon" color={Colors.Gray0} />}
          <select className={selectClass} {...field} value={fieldValue} disabled={disabled}>
            {/* Need to display none to hide on iOS Safari. Will be visible if no options are selected (out of hours apt) */}
            {!hidePlaceholder && (
              <option hidden value="" style={{ display: 'none' }}>
                {placeholder || 'Select'}
              </option>
            )}
            {selectOptions
              ? selectOptions.map(({ value, label, disabled }) => (
                  <option key={value} value={value} disabled={disabled}>
                    {label}
                  </option>
                ))
              : null}
          </select>
          <div className="select--chevron--holder">
            <FontAwesomeIcon
              icon={faAngleDown}
              className="select--chevron"
              color={disabled ? Colors.Gray5 : Colors.Gray0}
            />
          </div>
        </div>
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <div className="form--error">{message}</div>}
      />
    </div>
  );
};
