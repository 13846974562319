import { BlockStatus, DocumentItemWithStatus } from 'src/types/blocks';
import { useDriversLicenseFrontStatus } from 'src/components/context/status/useDriversLicenseFrontStatus';
import { useDriversLicenseBackStatus } from 'src/components/context/status/useDriversLicenseBackStatus';
import { useInsuranceStatus } from 'src/components/context/status/useInsuranceStatus';
import { useIncomeProofStatus } from 'src/components/context/status/incomeProof';
import { useResidenceProofStatus } from 'src/components/context/status/residenceProof';
import { useOtherDocumentStatus } from 'src/components/context/status/otherDocuments';
import { useMemo } from 'react';
import {
  DriversLicenseBackRoute,
  DriversLicenseFrontRoute,
  IncomeProofRoute,
  InsuranceRoute,
  OtherDocumentsRoute,
  ResidenceProofRoute,
} from 'src/constants/routes';

export function useUploadSteps() {
  const dlFrontStatus = useDriversLicenseFrontStatus();
  const dlBackStatus = useDriversLicenseBackStatus();
  const insurance = useInsuranceStatus();
  const incomeProofStatus = useIncomeProofStatus();
  const residenceProofStatus = useResidenceProofStatus();
  const otherDocumentStatus = useOtherDocumentStatus();

  const dlStatus = useMemo(() => {
    if (dlFrontStatus === BlockStatus.Hidden || dlBackStatus === BlockStatus.Hidden) {
      return BlockStatus.Hidden;
    }
    if (dlFrontStatus === BlockStatus.Done && dlBackStatus === BlockStatus.Done) {
      return BlockStatus.Done;
    }

    if (dlFrontStatus === BlockStatus.Default || dlBackStatus === BlockStatus.Default) {
      return BlockStatus.Default;
    }

    return BlockStatus.Incomplete;
  }, [dlFrontStatus, dlBackStatus]);

  const blockOrder = useMemo(() => {
    let array: DocumentItemWithStatus[] = [];

    array.push({ ...DriversLicenseFrontRoute, status: dlStatus });
    array.push({ ...DriversLicenseBackRoute, status: dlStatus });
    array.push({ ...InsuranceRoute, status: insurance });
    array.push({ ...IncomeProofRoute, status: incomeProofStatus });
    array.push({ ...ResidenceProofRoute, status: residenceProofStatus });
    array.push({ ...OtherDocumentsRoute, status: otherDocumentStatus });

    // for (let i = 0; i < referenceCount; i++) {
    //   let status: BlockStatus = referenceStatus[i.toString()];
    //   array.push({
    //     ...ReferencesRoute,
    //     index: i.toString(),
    //     route: `${ReferencesRoute.route}${status === BlockStatus.Done ? 'edit' : 'new'}?index=${i}`,
    //     status,
    //   });
    // }

    return array;
  }, [dlStatus, insurance, incomeProofStatus, residenceProofStatus, otherDocumentStatus]);

  return { blockOrder };
}
