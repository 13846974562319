import { useAuth } from 'src/components/context/AuthProvider';
import { ApiError, useApiFetch } from './useApiFetch';
import { useSessionContext } from 'src/components/context/SessionProvider';
import { useCallback } from 'react';
import { Reference } from 'src/types/reference-block';
import useSWRMutation from 'swr/mutation';
import useSWR from 'swr';

export function useReferences() {
  const { token } = useAuth();
  const apiFetcher = useApiFetch();
  const { sessionReferences } = useSessionContext();
  const key = `customers/references`;

  const deleteReference = useCallback(
    (url, { arg: { id } }) =>
      apiFetcher(`${url}/${id}`, {
        method: 'DELETE',
      }),
    [apiFetcher]
  );
  const { trigger: referencesDelete } = useSWRMutation(key, deleteReference);
  const { data, error } = useSWR<Reference[], ApiError>(token ? key : null, apiFetcher);

  if (!token) {
    return {
      references: sessionReferences ? (sessionReferences as Reference[]) : ([] as Reference[]),
      referencesIsLoading: false,
      referencesIsError: false,
      referencesDelete,
    };
  }

  return {
    references: data
      ? data.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      : [],
    referencesIsLoading: !error && !data,
    referencesIsError: undefined,
    referencesDelete,
  };
}
