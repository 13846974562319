import { useMemo } from 'react';
import { BlockRoute, BlockStatus } from 'src/types/blocks';
import { useDeals } from '../DealsProvider';
import { PaymentType } from 'src/types/deal';
import { Signer } from 'src/types/creditapplication-block';
import { useSessionContext } from '../SessionProvider';
import { useCreditApplications } from 'src/fetches/useCreditApplications';
import { useConfig } from '../config';

export const useApplyStatus = () => {
  const { deal } = useDeals()!;
  const config = useConfig()!;
  const { creditApplications } = useCreditApplications();
  const { creditAppVisited } = useSessionContext();

  return useMemo(() => {
    // Payment Type is Cash, let's hide from flow altogether
    if (
      config.blocks[BlockRoute.Apply].disabled ||
      deal.paymentType === PaymentType.Cash ||
      (config.paymentOptionsConfig?.disableFinancing && config.paymentOptionsConfig?.disableLeasing)
    ) {
      return BlockStatus.Hidden;
    }

    // User has 1 or more credit apps, or
    if (creditApplications?.filter(item => item.type === Signer.Primary)?.length > 0) {
      return BlockStatus.Done;
    }

    // User has been to credit app page and has a Payment Type of Financing or Leasing
    if (creditAppVisited === true) {
      return BlockStatus.Incomplete;
    }

    return BlockStatus.Default;
  }, [creditApplications, deal, creditAppVisited, config]);
};
