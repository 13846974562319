import { TradeInPayoffType } from 'src/types/tradein-block';
import { Radio } from './Radio';
import { FormError } from './FormError';

const payoffTypeInputs = [
  {
    label: 'Loan',
    labelDesc: 'Making payments to own this vehicle',
    type: TradeInPayoffType.Loan,
  },
  {
    label: 'Lease',
    labelDesc: 'Another party owns this vehicle',
    type: TradeInPayoffType.Lease,
  },
  {
    label: 'Neither',
    labelDesc: 'There are no financial obligations attached to this vehicle',
    type: TradeInPayoffType.Neither,
  },
];

export const PayoffType = () => {
  return (
    <>
      {payoffTypeInputs.map(item => (
        <Radio
          key={item.type}
          value={item.type}
          name="payoffType"
          label={item.label}
          labelDesc={item.labelDesc}
          circle={true}
        />
      ))}
      <FormError name="payoffType" />
    </>
  );
};
