import { useMemo } from 'react';
import { BlockRoute, BlockStatus } from 'src/types/blocks';
import { useSessionContext } from '../SessionProvider';
import { useTradeins } from 'src/fetches/useTradeins';
import { useConfig } from '../config';

export const useTradeInStatus = () => {
  const config = useConfig()!;
  const { tradeins } = useTradeins();
  const { tradeinsVisited, requireTradeIns } = useSessionContext();

  return useMemo(() => {
    // Backwards compatibility for old trade in config
    if (config.tradeinOptionsConfig?.disableTradein || config.blocks[BlockRoute.TradeIn].disabled) {
      return BlockStatus.Hidden;
    }

    // User has 1 or more trade apps, or has selected no trades
    if (tradeins?.length > 0 || requireTradeIns === false) {
      return BlockStatus.Done;
    }

    // User has been to trade ins page and has selected Yes for trades
    if (tradeinsVisited === true && requireTradeIns === true) {
      return BlockStatus.Incomplete;
    }

    return BlockStatus.Default;
  }, [tradeins, requireTradeIns, tradeinsVisited, config]);
};
