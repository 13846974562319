import { useMemo } from 'react';
import { BlockRoute, BlockStatus } from 'src/types/blocks';
import { PaymentType } from 'src/types/deal';
import { useDeals } from '../DealsProvider';
import { useAuth } from '../AuthProvider';
import { useSessionContext } from '../SessionProvider';
import { useConfig } from '../config';

export const useFinanceStatus = () => {
  const config = useConfig()!;
  const { token } = useAuth();
  const { deal, activeDealId, deals } = useDeals()!;
  const { paymentOptionsVisited } = useSessionContext();

  const vehicleId = useMemo(
    () => (!token ? (deal ? deal.vin : undefined) : activeDealId),
    [activeDealId, token, deal]
  );

  return useMemo(() => {
    if (config.blocks[BlockRoute.Finance].disabled) {
      return BlockStatus.Hidden;
    }

    if (!deal.vin) {
      return BlockStatus.Disabled;
    }

    if (deal.paymentType === PaymentType.Leasing && !deal.isLeaseEnabled) {
      return BlockStatus.Incomplete;
    }

    if (
      deal.isDeskingLocked ||
      (vehicleId && paymentOptionsVisited?.includes(vehicleId)) ||
      (deal.vin && deals?.some(el => el.vin === deal.vin))
    ) {
      return BlockStatus.Done;
    }

    //No incomplete status for this block
    return BlockStatus.Default;
  }, [deal, paymentOptionsVisited, vehicleId, config, deals]);
};
