import { useEffect, useRef, useState } from 'react';
import { Input } from './Input';
import { CodeInput } from './CodeInput';
import Button from '../form/Button';
import { useFormContext, useWatch } from 'react-hook-form';
import { Popover, PopoverTrigger } from '../floating/popover/Popover';
import { Tooltip } from '../floating/popover/Tooltip';
import { useFormSubmit } from '../form/Form';
import toast from 'react-hot-toast';
import { AuthService } from 'src/services/Auth';
import { useConfig } from '../context/config';
import { FormWarning } from '../form/FormWarning';

export function Authenticator({ setDisplayEmail, codeSent, setCodeSent }) {
  const config = useConfig()!;
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const code = useWatch({ name: 'code' });
  const formEmail = useWatch({ name: 'email' });
  const [countdown, setCountdown] = useState(codeSent ? 20 : 0);
  const { getValues } = useFormContext();
  const {
    handleFormGroupSubmit,
    formValues,
    isSubmitting: isFormSubmitting,
    setIsSubmitting,
  } = useFormSubmit();

  const handleNewCode = async () => {
    setCountdown(20);
    setIsSubmitting(true);
    await AuthService.requestCode(config.dealershipId, formEmail);
    toast.success('A new sign in code was sent to your email!');
    setCodeSent(true);
    setDisplayEmail(formEmail);
    setIsSubmitting(false);
  };

  const buttonText = () => {
    if (codeSent) {
      if (countdown > 0) {
        return `Resend Code Available in ${countdown}s`;
      } else {
        return 'Resend Code';
      }
    } else {
      return 'Send My Code';
    }
  };

  useEffect(() => {
    if (countdown === 0) return;

    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [countdown]);

  useEffect(() => {
    let timer;

    if (!formValues.codeSent) {
      timer = setTimeout(() => {
        setIsOpen(true);
      }, 3000);
    } else {
      setIsOpen(false);
    }

    // Cleanup function to cancel the timer if the error is resolved before the timeout
    return () => clearTimeout(timer);
  }, [formValues.codeSent]);

  useEffect(() => {
    // when code is 6 characters long, submit the form automatically
    if (code.length === 6) {
      if (codeSent) {
        handleFormGroupSubmit(getValues());
      } else {
        toast.error('Please send a code to your email first.');
      }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [code]);

  return (
    <div className="code-input--holder u-mar-top-8" ref={containerRef}>
      <div className="u-mar-top-8">
        <Input name="email" label={formEmail !== '' ? 'Email' : 'Verify Email'} />

        {formValues.email !== '' && formValues.email !== formEmail && (
          <div className="u-mar-top-8">
            <FormWarning text="Are you sure you want to change your email? Some information attached to this email may be lost" />
          </div>
        )}
      </div>
      <CodeInput name="code" />
      <div className="u-position-relative">
        <Button
          type="button"
          text={buttonText()}
          baseClass={codeSent ? 'btn mod-tertiary mod-full' : 'btn mod-primary mod-full'}
          isSubmitting={isFormSubmitting || countdown > 0}
          // progress={!isResendDisabled}
          handleSubmit={handleNewCode}
        />
        {/* Div for tooltip spacing since padding prop isn't working in this context */}
        <div className="u-mar-top-8"></div>
        {isOpen && (
          <Popover initialOpen={isOpen} placement="bottom">
            <PopoverTrigger className="popover-tooltip--hidden-trigger"></PopoverTrigger>
            <Tooltip
              text="Click to get your login code!"
              containerRef={containerRef}
              textClassName="popover-tooltip--text mod-medium"
            />
          </Popover>
        )}
      </div>
    </div>
  );
}
