import { createContext, useContext, useState } from 'react';

interface EmbeddedFormContextType {
  isEmbedded: boolean;
  key: number;
  resetForm: () => void;
}

const EmbeddedFormContext = createContext<EmbeddedFormContextType | null>(null);

export const useEmbeddedForm = () => {
  return useContext(EmbeddedFormContext);
};

export const EmbeddedFormProvider = ({ children }) => {
  const [key, setKey] = useState(0);
  const embeddedValue = { isEmbedded: true, key, resetForm: () => setKey(key + 1) };
  return (
    <EmbeddedFormContext.Provider value={embeddedValue}>{children}</EmbeddedFormContext.Provider>
  );
};
