import { createContext, useContext, ReactNode } from 'react';
import { FormOptionsInternal } from 'src/types/form';
import { useEmbeddedForm } from './EmbbededFormProvider';

// Create a generic type for the context
interface FormContextType<T> {
  props: T;
}

const FormContext = createContext<FormContextType<FormOptionsInternal> | undefined>(undefined);

type FormProviderProps<T> = {
  props: T;
  children: ReactNode;
};

// Generic FormProvider
export function FormPropsProvider<T extends FormOptionsInternal>({
  props,
  children,
}: FormProviderProps<T>) {
  const embbeddedForm = useEmbeddedForm();
  return (
    <FormContext.Provider value={{ props }} key={embbeddedForm?.key}>
      {children}
    </FormContext.Provider>
  );
}

// Hook to use the context
export function useFormProps<T extends FormOptionsInternal>() {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context.props as T;
}
