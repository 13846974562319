import { FormProvider, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { fireAnalyticsEvents } from 'src/handlers/useHandleFormAnalyticsEvent';
import { FormActions } from './FormActions';
import './Form.css';
import toast from 'react-hot-toast';
import { useAutoSave } from 'src/helpers/useAutosave';
import { useCustomFields } from './CustomFields';
import { FormAdditionalContent } from './FormAdditionalContent';
import { FormStepPropsProvider, useFormStepProps } from '../context/FormStepPropsProvider';
import { FormStepNotice } from './FormStepNotice';
import { StepInternal } from 'src/types/step';
import { useFormProps } from '../context/FormPropsProvider';
import { HostMessage } from 'src/types/hostMessage';
import RichContent from './RichContent';
import PageLoader from '../PageLoader';
// import { useFormProps } from '../context/FormPropsProvider';

interface FormSubmitContextProps {
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  activeForm: string;
  formValues: Partial<any>;
  setActiveForm: (activeForm: string) => void;
  handleFormGroupSubmit: (data: any) => Promise<void>;
  handleFormGroupBack: () => void;
  handleFormGroupSkip: () => void;
  handleFormCancel: () => void;
  formSteps: string[];
  activeStepIndex: number;
  conditionalFields?: {
    [key: string]: (data: any) => boolean;
  };
}

const FormSubmitContext = createContext<FormSubmitContextProps | undefined>(undefined);

export const useFormSubmit = () => {
  const context = useContext(FormSubmitContext);
  if (!context) {
    throw new Error('useFormSubmit must be used within a FormSubmitProvider');
  }
  return context;
};

interface FormSubmitProviderProps {
  children: React.ReactNode;
  handleFormSubmit: (data: any) => Promise<void>;
  handleFormCancel: any;
  multistepFormValues?: any;
  steps: string[];
  classNames?: string | null;
  conditionalFields?: {
    [key: string]: (data: any) => boolean;
  };
  savedForm?: boolean;
}

export const FormWrapper: React.FC<FormSubmitProviderProps> = ({
  children,
  classNames,
  handleFormSubmit,
  handleFormCancel,
  multistepFormValues = {},
  steps,
  conditionalFields = {},
  savedForm,
}) => {
  const props = useFormProps();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<Partial<any>>(multistepFormValues);
  const formSteps = useMemo(() => {
    const array: string[] = steps;

    //filter steps based on conditional fields. if no conditional field prop with step exists, include it. if it does, check if it should be included
    return array.filter(step => {
      if (conditionalFields[step] === undefined) {
        return true;
      }
      return !conditionalFields[step](formValues);
    });
  }, [formValues, steps, conditionalFields]);
  const [activeStepIndex, setActiveStepIndex] = useState(savedForm ? formSteps.length - 1 : 0);

  const handleFormGroupSubmit = async (data: any) => {
    const updatedFormData = {
      ...formValues,
      ...data,
    };

    window.parent.postMessage(
      {
        type: HostMessage.Submit,
        payload: {
          isOpen: 'test',
        },
      },
      '*'
    );

    setIsSubmitting(true);
    setFormValues(updatedFormData);

    fireAnalyticsEvents(
      props.conversion?.analyticsEventName || 'Space Auto Digital Retailing ' + props.form,
      formSteps[activeStepIndex]
    );

    if (activeStepIndex !== formSteps.length - 1) {
      setActiveStepIndex(prev => prev + 1);
      setIsSubmitting(false);
    } else {
      try {
        await handleFormSubmit(updatedFormData).finally(() => {
          setIsSubmitting(false);
        });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const setActiveForm = (activeForm: string) => {
    setActiveStepIndex(formSteps.findIndex(item => item === activeForm));
  };

  const handleFormGroupBack = () => {
    if (activeStepIndex === 0) {
      handleFormCancel();
    } else {
      setActiveStepIndex(prev => prev - 1);
    }
  };

  const handleFormGroupSkip = () => {
    setActiveStepIndex(prev => prev + 1);
  };

  if (formSteps[activeStepIndex] === undefined) {
    return <PageLoader />;
  }

  return (
    <FormSubmitContext.Provider
      value={{
        handleFormGroupSubmit,
        handleFormGroupBack,
        handleFormGroupSkip,
        setActiveForm,
        activeForm: formSteps[activeStepIndex],
        activeStepIndex,
        handleFormCancel,
        isSubmitting,
        setIsSubmitting,
        formValues,
        conditionalFields,
        formSteps,
      }}
    >
      <div className={`form-modal-wrapper ${classNames}`}>
        <div className="multi-step-form">{children}</div>
      </div>
    </FormSubmitContext.Provider>
  );
};

//Really Form Step Wrapper
function FormContent() {
  const props = useFormStepProps<StepInternal>();
  const { handleFormGroupSubmit, formValues } = useFormSubmit();
  const customFields: Partial<StepInternal> = useCustomFields();

  const form = useForm({
    resolver: joiResolver(Joi.object({ ...(props.schema || {}), ...customFields.schema }), {
      // Allows all unused value props to pass through multi-step forms
      allowUnknown: true,
    }),
    mode: 'onBlur',
    //Optionally pass in default values from the parent form
    // copy customFields.extraDefaultValues, spread props.data || formValues over it
    defaultValues: {
      ...customFields.extraDefaultValues,
      ...(props.data || formValues),
    },
  });
  useAutoSave(form, props.onSubmit || handleFormGroupSubmit, props.autosave);

  // console.log(form.getValues(), form.formState.errors);

  useEffect(() => {
    if (Object.keys(form.formState.errors).length > 0) {
      const errorMessages = Object.values(form.formState.errors)
        .map(error => error!.message)
        .join(', ');

      toast.error(errorMessages);
    }
  }, [form.formState.errors]);

  // console.log(form.formState.errors, form.getValues(), form.formState.isValid);

  // console.log(formSteps, activeStepIndex, activeForm, 'activeForm');
  // IMPROVE: preload next form step data
  // console.log(form.getValues(), form.formState.errors);
  return (
    <FormProvider {...form}>
      <form
        id={props.id}
        className="form-step--form"
        // className={renderClass}
        onSubmit={form.handleSubmit(props.onSubmit || handleFormGroupSubmit)}
        autoComplete={props.autocomplete}
      >
        <div className="form-step--content">
          {props.children}
          <FormAdditionalContent {...customFields} />
          <FormDisclaimer />
        </div>
        <FormActions />
      </form>
    </FormProvider>
  );
}

//Really Form Step Wrapper
export function FormContentWrapper(props: StepInternal) {
  const { activeForm, formSteps, activeStepIndex } = useFormSubmit();
  const isAnimated = props.isAnimated ?? true;

  const renderClass = useMemo(() => {
    const classes = ['form-step', `${isAnimated ? 'is-animated' : ''}`, props.className];
    if (activeForm === props.id) classes.push('active');
    if (activeStepIndex > formSteps.findIndex(item => item === props.id)) classes.push('left');
    if (activeStepIndex < formSteps.findIndex(item => item === props.id)) classes.push('right');
    return classes.join(' ');
  }, [activeForm, activeStepIndex, formSteps, props.id, props.className, isAnimated]);

  if (props.id !== activeForm) {
    return <div className={renderClass}></div>;
  }

  return (
    <div className={renderClass}>
      <FormStepPropsProvider props={props}>
        <FormContent />
      </FormStepPropsProvider>
    </div>
  );
}

export function useSpaceForm(resolver, defaultValues) {
  return useForm({
    resolver: joiResolver(Joi.object(resolver), {
      // Allows all unused value props to pass through multi-step forms
      allowUnknown: true,
    }),
    mode: 'onBlur',
    defaultValues,
  });
}

export function FormBody() {
  const props = useFormStepProps<StepInternal>();

  return (
    <>
      <div className="form-step--title-bar">
        {props.title && <div className="heading-6">{props.title}</div>}
        {props.body && <div className="text--section-title">{props.body}</div>}
      </div>
      <FormStepNotice />
    </>
  );
}

export function FormDisclaimer() {
  const props = useFormStepProps<StepInternal>();

  return (
    <>
      {props.disclaimer && (
        <div className="form-step--disclaimer">
          <div className="text--body-3">
            <RichContent content={props.disclaimer} />
          </div>
        </div>
      )}
    </>
  );
}
