import { useMemo } from 'react';
import { BlockStatus } from 'src/types/blocks';
import { useSessionContext } from '../SessionProvider';
import { useAuth } from '../AuthProvider';
import { useConfig } from '../config';
import { DocumentTag } from 'src/types/document-block';
import { useCustomer } from 'src/fetches/useCustomer';
import { useDocuments } from 'src/fetches/useDocuments';

export const useResidenceProofStatus = () => {
  const config = useConfig()!;
  const { documents } = useDocuments();
  const { documentsVisited } = useSessionContext();
  const { token } = useAuth();
  const { me } = useCustomer();

  return useMemo(() => {
    if ((token && !me?.requireResidenceProof) || (!token && !config.requireResidenceProof)) {
      return BlockStatus.Hidden;
    }

    if (documents?.some(item => item.tag === DocumentTag.Residence)) {
      return BlockStatus.Done;
    }

    // User has been to documents page
    if (documentsVisited === true) {
      return BlockStatus.Incomplete;
    }

    return BlockStatus.Default;
  }, [documentsVisited, config, token, documents, me]);
};
