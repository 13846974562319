import { useState, useEffect, createContext, useContext } from 'react';
import { API_URL } from '../../constants/env';
import { Dealership } from 'src/types/dealership';
import { useNavigate } from 'react-router-dom';
import { AppConfig } from 'src/types/app';
import { blocks as defaultBlocks } from 'src/constants/routes';

export type Config = Partial<AppConfig> &
  Dealership & {
    isRetailing: boolean;
    hostDomain: string;
  };

export const ConfigContext = createContext<Config | null>(null);

export function useConfigState(props: AppConfig) {
  const [config, setConfig] = useState<Config | null>(null);
  const navigate = useNavigate();
  const LOCALHOST_HOSTNAME = 'localhost';

  (window as any).parent.SpaceAutoDigitalRetailing.update = newState => {
    setConfig({ ...config, ...newState });
    if (newState?.form && newState?.form !== config?.form) {
      navigate(newState.form);
    }
  };

  useEffect(() => {
    let mounted = true;

    //TODO: big performance enhancement to prefetch this data
    //TODO: don't fetch every time we open retailing
    Promise.all([fetch(`${API_URL}/config`).then(res => res.json()) as Promise<Dealership>]).then(
      ([dynamicConfig]) => {
        const config: Config = {
          // Start with launch portal props
          ...dynamicConfig,
          // Override with init props
          ...props,
          // Override with runtime props
          baseApi: API_URL,
          isRetailing: !props,
          hostGlobal: globalThis as typeof globalThis,
          hostDomain: globalThis.location?.hostname || LOCALHOST_HOSTNAME,
          retailingDomain: props?.retailingDomain || '',
          blocks: {
            ...defaultBlocks,
            ...dynamicConfig.blocks,
            ...props?.blocks,
          },
          // Test props here
          // packages: {
          //   desking: true,
          //   hideprices: false,
          //   retailing: true,
          //   crm: true,
          //   ims: true,
          //   daisi: true,
          //   sms: true,
          // },
          // hidePayments: false,
        };
        if (mounted) {
          setConfig(config);
        }
      }
    );

    return function cleanup() {
      mounted = false;
    };
  }, [props]);

  return config;
}

export function useConfig() {
  const config = useContext(ConfigContext);
  return config;
}
