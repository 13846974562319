import { FormWarning, FormWarningType } from './FormWarning';
import { useFormStepProps } from '../context/FormStepPropsProvider';
import { StepInternal } from 'src/types/step';

export function FormStepNotice() {
  const props = useFormStepProps<StepInternal>();

  if (props?.notice) {
    return (
      <FormWarning type={props.notice.type || FormWarningType.Activity} text={props.notice.text} />
    );
  }
  return null;
}
