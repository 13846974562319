import { createContext, useState, useContext } from 'react';
import { ApiError } from '../../fetches/useApiFetch';
import { useConfig } from './config';
import { ModalLoader } from '../floating/dialog/ModalLoader';
import { useSwrDeals } from 'src/fetches/useDeals';
import { MultipleDealResponse, SingleDealResponse, UnauthorizedDealResponse } from 'src/types/deal';
import { useSiteDeal } from 'src/fetches/useDeal';
import { KeyedMutator } from 'swr';
import { useAuth } from './AuthProvider';

export type SiteDeal = Partial<SingleDealResponse | UnauthorizedDealResponse>;

interface DealsContextProps {
  deals: MultipleDealResponse[];
  activeDealId: string;
  deal: SiteDeal;
  selectDeal: (dealId: string, navigateToDeal?: boolean) => void;
  dealUpsert: (dealData: any) => Promise<void>;
  dealMutate: KeyedMutator<SingleDealResponse>;
  dealIsMutating: boolean;
  dealIsLoading: boolean;
  dealIsError: ApiError | undefined;
}

// Create a context
const DealsContext = createContext<DealsContextProps | undefined>(undefined);

// Custom hook to use the DealsContext
export const useDeals = () => {
  const context = useContext(DealsContext);

  if (context === undefined) {
    throw new Error('useDeals must be used within a DealsProvider');
  }
  return context;
};

// DealsProvider component
const DealsProvider = ({ children }: { children: React.ReactNode }) => {
  const config = useConfig()!;
  const { token } = useAuth();
  // State to store active deal ID
  const [activeVin, setActiveVin] = useState<any>(config.vehicle?.vin || undefined);
  const { deals, dealsIsLoading, dealsCreate } = useSwrDeals();
  const { deal, dealIsLoading, dealIsError, dealMutate, dealUpsert, dealIsMutating } = useSiteDeal(
    activeVin // passed as activeDealId
  );

  // Function to select a deal
  const selectDeal = (vin: string) => {
    // 1. Add deal to the local storage if it doesn't exist
    if (!deals?.find(el => el.vin === vin)) {
      dealsCreate({ vin });
    }
    // 2. Set the active deal ID
    setActiveVin(vin);
  };

  if (!deals || !deal || dealsIsLoading || dealIsLoading) {
    return <ModalLoader />;
  }

  return (
    <DealsContext.Provider
      value={{
        deals,
        deal,
        activeDealId: token ? deals.find(el => el.vin === activeVin)?.id ?? null : deal,
        selectDeal,
        dealUpsert,
        dealMutate,
        dealIsMutating,
        dealIsLoading,
        dealIsError,
      }}
    >
      {children}
    </DealsContext.Provider>
  );
};

export default DealsProvider;
