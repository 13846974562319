/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect } from 'react';
import { Select, SelectOption } from './Select';
import { add } from 'date-fns';
import { useFormContext, useWatch } from 'react-hook-form';
import { SearchBy } from 'src/types/tradein-block';
import { useFormSubmit } from '../form/Form';
import { useApiFetch } from 'src/fetches/useApiFetch';
import toast from 'react-hot-toast';

const JDP_MIN_VALUATION_MODELYEAR = 2000;
const MAX_VALUATION_MODELYEAR = add(new Date(), { years: 1 }).getFullYear();
const yearOptions: SelectOption[] = [];

for (let year = MAX_VALUATION_MODELYEAR; year >= JDP_MIN_VALUATION_MODELYEAR; --year) {
  yearOptions.push({ value: year.toString(), label: year.toString() });
}

export const Year = ({ setIsSearching, setMakeOptions, setModelOptions, setBodyOptions }) => {
  const apiFetch = useApiFetch();
  const { formValues } = useFormSubmit();
  const year = useWatch({ name: 'year' });
  const searchBy = useWatch({ name: 'searchBy' });
  const { setValue, resetField } = useFormContext();
  // TODO: address editmode
  const editMode = false;

  const handleMakesSearch = useCallback(async (year: string) => {
    setIsSearching(true);

    const queryParams = new URLSearchParams({ year }).toString();
    const path = `decode/makes?${queryParams}`;

    try {
      const makes: string[] = await apiFetch(path);
      const options = makes.map(make => ({
        value: make,
        label: make,
      }));
      setMakeOptions(options);
      setModelOptions(undefined);
      setBodyOptions(undefined);
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setIsSearching(false);
    }
  }, []);

  useEffect(() => {
    if (year) {
      if (searchBy !== SearchBy.Vin) {
        if (editMode && formValues.year !== year) {
          setValue('make', '');
          setValue('model', '');
          setValue('ucgvehicleid', '');
          setValue('body', '');
          handleMakesSearch(year.toString());
        } else {
          resetField('make');
          resetField('model');
          resetField('ucgvehicleid');
          resetField('body');
          handleMakesSearch(year.toString());
        }
      }
    }
  }, [year]);

  return (
    <Select
      className="select__ymmt"
      name="year"
      key="year"
      selectOptions={yearOptions}
      label="Year"
      status={true}
    />
  );
};
