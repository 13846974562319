// /* eslint-disable react-hooks/exhaustive-deps */
import { FormBody, FormContentWrapper, useFormSubmit } from '../form/Form';
import toast from 'react-hot-toast';
import { email } from 'src/constants/schemas';
import { useLocation } from 'react-router-dom';
import { LoginState } from '../authentication/LoginForm';
import { AuthService } from 'src/services/Auth';
import { useConfig } from '../context/config';
import { useCustomFormStep } from '../form/FormStepProps';
import { Email } from '../controls/Email';

export const EmailFormId = 'loginEmailForm';
export const EmailForm = () => {
  const config = useConfig()!;
  const { handleFormGroupSubmit, setIsSubmitting } = useFormSubmit();
  const location = useLocation();
  const stepProps = useCustomFormStep(EmailFormId);

  const onSubmit = async (data: any) => {
    try {
      setIsSubmitting(true);
      await AuthService.requestCode(config.dealershipId, data.email);
      toast.success('A sign in code was sent to your email!');
      handleFormGroupSubmit({ ...data, codeSent: true });
    } catch (e: any) {
      toast.error(e.message);
      setIsSubmitting(false);
    }
  };

  const baseProps = {
    id: 'loginEmailForm',
    title: 'We need to verify your identity',
    body:
      location.state === LoginState.Verify
        ? 'In order to protect your information, you will need to verify your email before you can access this saved information.'
        : 'Dealing with passwords is a pain. Enter your email and we will send you a temporary sign in code.',
    schema: { email },
  };

  const formStepProps = {
    ...baseProps,
    ...stepProps,
    onSubmit,
  };

  return (
    <FormContentWrapper {...formStepProps}>
      <FormBody />
      <div className="u-mar-top-16">
        <Email />
      </div>
    </FormContentWrapper>
  );
};
