import {
  faFileInvoiceDollar,
  faQuestionCircle,
  faStar,
  faSteeringWheel,
} from '@fortawesome/pro-regular-svg-icons';
import { HelpType } from 'src/types/helpType';
import { Radio } from './Radio';
import { FormError } from './FormError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Colors } from 'src/types/colors';

const HelpTypeInputs = [
  {
    label: 'Schedule Test Drive',
    icon: faSteeringWheel,
    value: HelpType.TestDrive,
  },
  {
    label: 'Get Personalized Quote',
    icon: faFileInvoiceDollar,
    value: HelpType.Quote,
  },
  {
    label: 'Start Reservation',
    icon: faStar,
    value: HelpType.Reservation,
  },
  {
    label: 'Ask Us Something',
    icon: faQuestionCircle,
    value: HelpType.Support,
  },
];

export const HelpTypeControl = () => {
  return (
    <>
      {HelpTypeInputs.map(item => (
        <Radio
          icon={<FontAwesomeIcon icon={item.icon} color={Colors.Blue1} />}
          key={item.value}
          value={item.value}
          name="helpType"
          label={item.label}
          circle={true}
        />
      ))}
      <FormError name="helpType" />
    </>
  );
};
