import { useNavigate } from 'react-router-dom';
import { MenuProvider } from '../context/MenuProvider';
import DealsProvider from '../context/DealsProvider';
import { LocationProvider } from '../context/LocationProvider';
import { ScheduleProvider } from '../context/ScheduleProvider';
import { useAuth } from '../context/AuthProvider';
import { MobileProvider } from '../context/MobileProvider';
import { SessionProvider } from '../context/SessionProvider';
import { MinimizeProvider } from '../context/MinimizeProvider';
import { Tray } from './Tray';
import { Page } from './Page';
import { NavigationProvider } from '../context/NavigationProvider';
import { useConfig } from '../context/config';
import { EmbeddedForm } from './EmbeddedForm';
import { Trigger } from 'src/components/layout/Trigger';
import { Modal } from './Modal';
// import { Snackbar } from 'src/widgets/Snackbar';

export const Layout = () => {
  const config = useConfig();
  return (
    <MobileProvider>
      <SessionProvider>
        <DealsProvider key={config?.vehicle?.vin}>
          <LocationProvider>
            <ScheduleProvider>
              <NavigationProvider>
                <MinimizeProvider>
                  <EmbeddedForm />
                  <Modal>
                    <Tray />
                    <Trigger />
                  </Modal>
                  {/* <Snackbar /> */}
                </MinimizeProvider>
              </NavigationProvider>
            </ScheduleProvider>
          </LocationProvider>
        </DealsProvider>
      </SessionProvider>
    </MobileProvider>
  );
};
export function Layout2({ dealershipLogoUrl }) {
  const navigate = useNavigate();

  const { signout } = useAuth();
  // if end of location pathname is 'offer' or 'offer/' redirect to 'offer/1'

  const handleLogout = async () => {
    signout(() => {
      navigate('/login?message=loggedOut');
    });
  };

  return (
    <MobileProvider>
      <SessionProvider>
        <MinimizeProvider>
          <DealsProvider>
            <LocationProvider>
              <ScheduleProvider>
                <NavigationProvider>
                  <MenuProvider>
                    <Page handleLogout={handleLogout} dealershipLogoUrl={dealershipLogoUrl} />
                  </MenuProvider>
                </NavigationProvider>
              </ScheduleProvider>
            </LocationProvider>
          </DealsProvider>
        </MinimizeProvider>
      </SessionProvider>
    </MobileProvider>
  );
}
