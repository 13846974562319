import React, { createContext, ReactNode, useContext } from 'react';
import { BlockRoute, BlockStatus } from 'src/types/blocks';
import { useTradeInStatus } from './status/tradeIn';
import { useDocumentStatus } from './status/useDocumentStatus';
import { useAppointmentsStatus } from './status/useAppointmentsStatus';
import { useAccountStatus } from './status/useAccountStatus';
import { useReferencesStatus } from './status/useReferencesStatus';
import { useFinanceStatus } from './status/finance';
import { useApplyStatus } from './status/useApplyStatus';

interface NavigationContextProps {
  [BlockRoute.TradeIn]: BlockStatus;
  [BlockRoute.Finance]: BlockStatus;
  [BlockRoute.Apply]: BlockStatus;
  [BlockRoute.Appointments]: BlockStatus;
  [BlockRoute.Documents]: BlockStatus;
  [BlockRoute.References]: BlockStatus;
  [BlockRoute.Preferences]: BlockStatus;
}

interface NavigationProviderProps {
  children: ReactNode;
}
const StatusContext = createContext<NavigationContextProps | undefined>(undefined);

export const NavigationProvider: React.FC<NavigationProviderProps> = ({ children }) => {
  const tradeInsStatus = useTradeInStatus();
  const financeStatus = useFinanceStatus();
  const applyStatus = useApplyStatus();
  const appointmentsStatus = useAppointmentsStatus();
  const referencesStatus = useReferencesStatus();
  const uploadsStatus = useDocumentStatus(referencesStatus, applyStatus);
  const accountStatus = useAccountStatus();

  return (
    <StatusContext.Provider
      value={{
        [BlockRoute.TradeIn]: tradeInsStatus,
        [BlockRoute.Finance]: financeStatus,
        [BlockRoute.Apply]: applyStatus,
        [BlockRoute.Appointments]: appointmentsStatus,
        [BlockRoute.Documents]: uploadsStatus,
        [BlockRoute.References]: referencesStatus,
        [BlockRoute.Preferences]: accountStatus,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export const useStatus = () => {
  const context = useContext(StatusContext);
  if (!context) {
    throw new Error('useStatus must be used within a StatusProvider');
  }
  return context;
};
