import { useMemo } from 'react';
import { useCustomer } from 'src/fetches/useCustomer';
import { BlockStatus } from 'src/types/blocks';

export const useAccountStatus = () => {
  const { me } = useCustomer();
  return useMemo(() => {
    // User has added first name
    if (me?.firstName?.length > 0) {
      return BlockStatus.Done;
    }
    return BlockStatus.Default;
  }, [me]);
};
