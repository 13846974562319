export enum DynamicTextEnum {
  privacyPolicy = 'privacy-policy',
  dealershipName = 'dealership-name',
  termsOfService = 'terms-of-service',
  contact = 'contact',
  hide = 'hide',
}

export const DynamicText = ({ config, dynamicText }) => {
  const content = () => {
    switch (dynamicText) {
      case DynamicTextEnum.privacyPolicy:
        return (
          <a
            href={`https://${config.websiteDomain}/privacy-policy/`}
            target="_blank"
            className="u-blue-link"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        );
      case DynamicTextEnum.dealershipName:
        return config.dealershipName;
      case DynamicTextEnum.contact:
        return (
          <a
            href={`https://${config.websiteDomain}/contact-us/`}
            target="_blank"
            className="u-blue-link"
            rel="noreferrer"
          >
            Contact Us
          </a>
        );
      case DynamicTextEnum.hide:
        return null;
      default:
        console.error(
          `Dynamic text not found: ${dynamicText}. Please check your spelling or request new dynamic text.`
        );
        return null;
    }
  };
  return content();
};
