import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { DocumentTag } from './document-block';

export enum BlockStatus {
  Done = 'done',
  Incomplete = 'incomplete',
  Disabled = 'disabled', // Only a local prop
  Hidden = 'hidden', // Only a local prop
  Blocked = 'blocked', // Only a local prop
  Default = 'default',
}

// Used externally. Do not change existing values
export enum BlockRoute {
  Preferences = 'preferences',
  CreditApplication = 'credit-application',
  TradeIn = 'trade-in',
  Appointments = 'appointments',
  Documents = 'documents',
  Offers = 'offers', // Deprecated
  Review = 'review',
  Vehicle = 'vehicle',
  Inventory = 'inventory',
  References = 'references',
  ThankYou = 'thank-you',
  Apply = 'apply',
  Chat = 'chat',
  Login = 'login',
  Directions = 'directions',
  Finance = 'finance', // New
  Menu = 'menu', // New
}

export enum FullBlockRoute {
  Preferences = `/retailing/menu/preferences/`,
  CreditApplication = `/retailing/credit-application/`,
  TradeIn = `/retailing/trade-in/`,
  Appointments = `/retailing/appointments/`,
  Documents = `/retailing/menu/documents/`,
  References = `/retailing/menu/references/`,
  Review = `/retailing/review/`,
  Vehicle = `/retailing/menu/vehicle`,
  Apply = '/retailing/apply/',
  Chat = '/retailing/chat/',
  Finance = '/retailing/finance/', // New
  Menu = '/retailing/menu/', // New
  Login = '/retailing/login/',
}

export interface RouteItem {
  name: BlockRoute;
  disabled: boolean;
  title: string;
  subtitle?: string;
  subtitleDone?: string;
  route: FullBlockRoute;
  icon: IconDefinition;
  iconDone?: IconDefinition;
  websiteRoute?: string;
  isDealDependent: boolean;
  tip?: string;
  alternateTitle?: string;
  alternateIcon?: IconDefinition;
  parentBlock?: BlockRoute;
  index?: string;
  isIndexPage?: boolean;
  moduleTitle?: string;
  navTitle?: string;
  redirect?: string; // Prop that only website uses
  // isDone?: boolean;
}

export interface RouteItemWithStatus extends RouteItem {
  status: BlockStatus;
}

export interface DocumentItem {
  name: DocumentTag | string;
  title: string;
  titleSpecific?: string;
  route: string;
  parentBlock?: BlockRoute;
  icon: IconDefinition;
  iconDone?: IconDefinition;
  isIndexPage?: boolean;
}

export interface DocumentItemWithStatus extends DocumentItem {
  status: BlockStatus;
  index?: string;
}
