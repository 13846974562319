import { EmploymentStatus } from 'src/types/employmentStatus';

export const EmploymentStatusInputs = [
  {
    label: 'None',
    labelDesc: 'Not currently employed',
    name: 'employmentStatus',
    value: EmploymentStatus.Unemployed,
  },
  {
    label: 'Retired',
    labelDesc: 'Retired and not actively working',
    name: 'employmentStatus',
    value: EmploymentStatus.Retired,
  },
  {
    label: 'Employed',
    labelDesc: 'Employed by a company or organization',
    name: 'employmentStatus',
    value: EmploymentStatus.Employed,
  },
  {
    label: 'Self-Employed',
    labelDesc: 'Own business or independent contractor',
    name: 'employmentStatus',
    value: EmploymentStatus.SelfEmployed,
  },
  {
    label: 'Other',
    labelDesc: 'Any other employment status',
    name: 'employmentStatus',
    value: EmploymentStatus.Other,
  },
];
