import { Input } from 'src/components/controls/Input';
import { Select } from 'src/components/controls/Select';
import { Textarea } from 'src/components/controls/Textarea';
import Checkbox from 'src/components/controls/Checkbox';
import { AdditionalField } from 'src/types/additionalField';

interface AdditionalFieldControlProps {
  fieldConfig: AdditionalField;
  // Replace 'any' with the appropriate type for 'control'
}

export function AdditionalFieldControl({ fieldConfig }: AdditionalFieldControlProps) {
  switch (fieldConfig.type) {
    case 'text':
    case 'number':
      return <Input {...fieldConfig} />;
    case 'textarea':
      return <Textarea {...fieldConfig} />;
    case 'checkbox':
      return <Checkbox {...fieldConfig} />;
    case 'select': {
      const params = {
        ...fieldConfig,
        selectOptions: fieldConfig.options,
      };
      return <Select {...params} />;
    }
    case 'hidden':
      return null;
    default:
      throw new Error(`Unknown additional field type`);
  }
}
