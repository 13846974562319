import { useFormStepProps } from '../context/FormStepPropsProvider';
import Button from './Button';
import { useFormSubmit } from './Form';

export function FormSkipAction() {
  const { handleFormGroupSkip, isSubmitting } = useFormSubmit();
  const props = useFormStepProps();

  if (!props.isSkipStepVisible) {
    return null;
  }

  return (
    <Button
      isSubmitting={isSubmitting}
      baseClass="btn mod-tertiary mod-tall"
      type="button"
      handleSubmit={handleFormGroupSkip}
      text="Skip"
    />
  );
}
